import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { nanoid } from "@reduxjs/toolkit";
import classNames from "classnames";
import dayjs from "dayjs";

import { useLazyRetrieveReservationQuery } from "../../../store/services/ReservationService";
import { useLazyGetShipContentQuery } from "../../../store/services/ContentService";
import { ReservationListInterface } from "../../../store/slices/reservationSlice";
import { useTypedDispatch, useTypedSelector } from "../../../store/store";
import { CURRENT_BOOKINGS, ROOT } from "../../../utils/constants/routes";
import { currencyToFormat } from "../../../utils/helpers/currency";
import { showModal } from "../../../store/slices/modalSlice";
import MODAL from "../../../utils/constants/modal";

import {
  getCancellationPolicyDaysRange,
  getCancellationPolicyValue,
} from "../../../utils/helpers/cancellationPolicy";

import LoadingContainer from "../../containers/LoadingContainer";
import CustomBreadcrumbs from "../../shared/CustomBreadcrumbs";
import CustomCarousel from "../../shared/Carousel";
import SvgButton from "../../shared/SvgButton";

import styles from "./index.module.scss";

function Booking() {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [getReservation] = useLazyRetrieveReservationQuery();
  const [getShip] = useLazyGetShipContentQuery();

  const { pnr = "" } = useParams();

  const { agency: channel_partner, agent: agency } = useTypedSelector(
    (state) => state.session,
  );

  const { created_by, market, commission_level } = useTypedSelector(
    (state) => state.agency,
  );

  const { date_format } = useTypedSelector((state) => state.environment);
  const { account_type } = useTypedSelector((state) => state.agency);
  const { ship } = useTypedSelector((state) => state.content);

  const reservation = useTypedSelector(
    (state) => state.reservation.reservation,
  );

  const sailing = useMemo(() => reservation?.sailings?.[0], [reservation]);

  const cancellationPolicies = useMemo<
    Array<{
      price_type: string;
      type: string;
      value: number;
      days_before_departure: number;
    }>
  >(
    () =>
      reservation.sailings
        .flatMap((sailing) =>
          sailing.cabins.flatMap((cabin) => cabin.cancellation_policy),
        )
        .filter((item) => item),
    [reservation],
  );

  const [isLoading, setIsLoading] = useState(true);

  const showPaymentModal = (pnr: ReservationListInterface["pnr"]) => () => {
    dispatch(showModal({ type: MODAL.MODAL_TYPES.PAYMENT, data: pnr }));
  };

  const initShip = async () => {
    try {
      await getShip({
        ship_code: sailing?.ship_code ?? "",
      });

      setIsLoading(false);
    } catch (error) {
      console.log("initShip error: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getReservation({ pnr, channel_partner, agency });
  }, []);

  useEffect(() => {
    if (sailing.ship_code) {
      initShip();
    }
  }, [sailing.ship_code]);

  return (
    <LoadingContainer isLoading={isLoading}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.crumbsAndButton}>
            <CustomBreadcrumbs
              crumbsList={[
                [ROOT, "HOME"],
                [CURRENT_BOOKINGS, "CURRENT BOOKINGS"],
                pnr ?? "BOOKING",
              ]}
            />

            <button className={styles.backButton} onClick={() => navigate(-1)}>
              {t("Back to all bookings")}
            </button>
          </div>

          <div className={styles.bookingInfoContainer}>
            <div className={styles.topSection}>
              <img
                src={ship?.images?.[0].image ?? ""}
                className={styles.mainImage}
              />

              <div className={styles.reservationInfo}>
                <div className={styles.reservationItem}>
                  <div className={styles.paymentButtonContainer}>
                    <p className={styles.pnr}>{reservation?.pnr ?? ""}</p>

                    <SvgButton
                      icon="FiDollarSign"
                      iconClassName={styles.svgAction}
                      onClick={showPaymentModal(reservation.pnr)}
                      isDisabled={
                        reservation.status === "cancelled" ||
                        account_type === "creditaccount"
                      }
                    />
                  </div>

                  <p className={styles.cruiseName}>
                    {reservation?.sailings?.[0]?.cruise_name ?? ""}
                  </p>

                  <p className={styles.ship}>
                    {reservation?.sailings?.[0]?.ship_name ?? ""}{" "}
                    {`(${reservation?.sailings?.[0]?.ship_code ?? ""})`}
                  </p>

                  <p className={styles.embarking}>
                    {`${t("Embarking")} `}
                    {dayjs(
                      reservation?.sailings?.[0]?.embarkation_date ?? "",
                    ).format(date_format)}{" "}
                    {reservation?.sailings?.[0]?.cruise_duration ?? ""}
                    {` ${t("nights")} `}(
                    {reservation?.sailings?.[0]?.sailing_code ?? ""})
                  </p>
                </div>

                <div className={styles.reservationItem}>
                  <p className={styles.guestInfo_primary}>
                    {reservation?.lead_title ?? ""}{" "}
                    {reservation?.lead_given_name ?? ""}{" "}
                    {reservation?.lead_lastname ?? ""}
                  </p>

                  <p className={styles.guestInfo_secondary}>
                    {reservation?.lead_email ?? ""}
                  </p>

                  <p className={styles.guestInfo_secondary}>
                    {reservation?.lead_phone ?? ""}
                  </p>
                </div>

                <div className={styles.reservationItem}>
                  <p className={styles.priceInfo_price}>
                    {currencyToFormat(Number(reservation?.total_price ?? "0"))}
                  </p>

                  <p className={styles.priceInfo_description}>
                    {reservation?.sailings?.[0]?.rate_code ?? ""}
                  </p>
                </div>
              </div>
            </div>

            <div className={styles.tableContainer}>
              <h2 className={styles.tableContainer_title}>{t("passengers")}</h2>

              <div className={styles.tableWrapper}>
                <table>
                  <thead>
                    <tr>
                      <th>{t("name")}</th>
                      <th>{t("date of birth")}</th>
                      <th>{t("nationality")}</th>
                      <th>{t("price")}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {(reservation?.sailings?.[0]?.cabins ?? []).map((cabin) => {
                      return (cabin?.guests ?? []).map((guest) => {
                        const {
                          uuid,
                          title,
                          given_name,
                          lastname,
                          date_of_birth,
                          nationality,
                          pricing,
                        } = guest;

                        return (
                          <tr key={uuid}>
                            <td>
                              <p>{`${title} ${given_name} ${lastname}`}</p>
                            </td>

                            <td>
                              <p>{dayjs(date_of_birth).format(date_format)}</p>
                            </td>

                            <td>
                              <p>{`${nationality ?? t("Not specified")}`}</p>
                            </td>

                            <td>
                              <p className={styles.textContent}>
                                <span>{`${t("Fare")}: `}</span>
                                {`${currencyToFormat(pricing.fare || "0")}`}
                              </p>

                              <p className={styles.textContent}>
                                <span>{`${t("Taxes")}: `}</span>
                                {`${currencyToFormat(pricing.gft || "0")}`}
                              </p>

                              <p className={styles.textContent}>
                                <span>{`${t("Extras")}: `}</span>
                                {`${currencyToFormat(pricing.extras || "0")}`}
                              </p>
                            </td>
                          </tr>
                        );
                      });
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            <div className={styles.tableContainer}>
              <h2 className={styles.tableContainer_title}>{t("cabins")}</h2>

              <div className={styles.info}>
                {(reservation?.sailings?.[0]?.cabins ?? []).map((cabin) => {
                  const {
                    guests,
                    deck_name = "",
                    cabin_number,
                    grade_code,
                  } = cabin;

                  const grade = ship.grades.find(
                    (el) => el?.code === grade_code,
                  );

                  return (
                    <div key={cabin.uuid} className={styles.cabin}>
                      <CustomCarousel
                        items={grade?.images.map((el) => el.image) ?? []}
                        className={styles.carousel}
                      />

                      <div className={styles.cabinInfo}>
                        <div className={styles.guests}>
                          {(guests ?? []).map((guest) => {
                            const { uuid, title, given_name, lastname } = guest;

                            return (
                              <div key={uuid} className={styles.guest}>
                                <p>{`${title} ${given_name} ${lastname}`}</p>
                              </div>
                            );
                          })}
                        </div>

                        <div className={styles.deckInfo}>
                          <p>
                            <span>{`${t("Deck")}: `}</span>

                            {deck_name?.split(" ")[1]}
                          </p>

                          <p>
                            <span>{`${t("Stateroom")}: `}</span>
                            {cabin_number}
                          </p>
                        </div>

                        <p className={styles.gradeName}>{grade?.name ?? ""}</p>

                        <p className={styles.gradeDescription}>
                          {grade?.description?.[0]?.description ?? ""}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className={styles.tableContainer}>
              <div>
                <h2 className={styles.tableContainer_title}>
                  {t("itinerary")}
                </h2>

                <p className={styles.tableContainer_subtitle}>
                  {sailing.cruise_name}
                </p>
              </div>

              <div className={styles.tableWrapper}>
                <table>
                  <thead>
                    <tr>
                      <th>{t("date")}</th>
                      <th>{t("port")}</th>
                      <th>{t("arrive")}</th>
                      <th>{t("depart")}</th>
                      <th>{t("tender")}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {sailing.itinerary.map((item) => {
                      const {
                        date,
                        port_name,
                        port_code,
                        arrive_time,
                        depart_time,
                        tender,
                      } = item;

                      return (
                        <tr key={nanoid()}>
                          <td>
                            <p>{dayjs(date).format(date_format)}</p>
                          </td>

                          <td>
                            <p>{`${port_name} (${port_code})`}</p>
                          </td>

                          <td>
                            <p>{`${arrive_time ?? "-"}`}</p>
                          </td>

                          <td>
                            <p>{`${depart_time ?? "-"}`}</p>
                          </td>

                          <td>
                            <p>{t(tender ? "Yes" : "No")}</p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            <div className={styles.paymentContainer}>
              <div className={styles.tableContainer}>
                <div>
                  <h2 className={styles.tableContainer_title}>{t("agency")}</h2>

                  <p className={styles.tableContainer_subtitle}>{created_by}</p>

                  <p
                    className={classNames(
                      styles.tableContainer_subtitle,
                      styles.uppercase,
                    )}>{`${t("market")}: ${market}`}</p>

                  <p
                    className={classNames(
                      styles.tableContainer_subtitle,
                      styles.uppercase,
                    )}>{`${t("commission")}: ${currencyToFormat(Number(reservation?.total_price) * (Number(commission_level) / 100))}`}</p>
                </div>
              </div>

              <div className={styles.tableContainer}>
                <div>
                  <h2 className={styles.tableContainer_title}>
                    {t("cancellation policies")}
                  </h2>

                  {cancellationPolicies.length !== 0 ? (
                    <div className={styles.tableWrapper}>
                      <table>
                        <thead>
                          <tr>
                            <th>{t("days before departure")}</th>
                            <th>{t("value")}</th>
                          </tr>
                        </thead>

                        <tbody>
                          {(cancellationPolicies ?? []).map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <p>
                                    {getCancellationPolicyDaysRange(
                                      item?.days_before_departure,
                                    )}
                                  </p>
                                </td>

                                <td>
                                  <p>
                                    {getCancellationPolicyValue({
                                      ...item,
                                      currency: reservation.currency,
                                    })}
                                  </p>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <p className={styles.tableContainer_subtitle}>
                      {`${t("There is currently no Cancellation Policies active on this booking.")}`}
                    </p>
                  )}
                </div>
              </div>

              <div className={styles.tableContainer}>
                <div>
                  <div className={styles.paymentButtonContainer}>
                    <h2 className={styles.tableContainer_title}>
                      {t("payment schedule")}
                    </h2>

                    <SvgButton
                      icon="FiDollarSign"
                      iconClassName={styles.svgAction}
                      onClick={showPaymentModal(reservation.pnr)}
                      isDisabled={
                        reservation.status === "cancelled" ||
                        account_type === "creditaccount"
                      }
                    />
                  </div>

                  <p className={styles.tableContainer_subtitle}>
                    {`${t("Next payment of")} ${currencyToFormat(Number(reservation?.next_payment_outstanding ?? "0"))} ${t("on")} ${dayjs(reservation?.next_payment_due ?? null).format(date_format)}`}
                  </p>
                </div>

                <div className={styles.tableWrapper}>
                  <table>
                    <thead>
                      <tr>
                        <th>{t("payment date")}</th>
                        <th>{t("payment amount")}</th>
                      </tr>
                    </thead>

                    <tbody>
                      {(reservation?.payment_schedule ?? []).map((item) => {
                        const { due_date, amount } = item;

                        return (
                          <tr key={nanoid()}>
                            <td>
                              <p>{dayjs(due_date).format(date_format)}</p>
                            </td>

                            <td>
                              <p>{currencyToFormat(Number(amount))}</p>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className={styles.tableContainer}>
                <div>
                  <h2 className={styles.tableContainer_title}>
                    {t("transactions")}
                  </h2>

                  {reservation?.transactions?.[
                    reservation?.transactions?.length - 1
                  ] ? (
                    <p className={styles.tableContainer_subtitle}>
                      {`${t("Last transaction of")} ${currencyToFormat(Number(reservation?.transactions?.[reservation?.transactions?.length - 1]?.amount ?? "0"))} ${t("was")} ${dayjs(reservation?.transactions?.[reservation?.transactions?.length - 1]?.made_on ?? null).format(date_format)}`}
                    </p>
                  ) : (
                    <p className={styles.tableContainer_subtitle}>
                      {t("No transactions found")}
                    </p>
                  )}
                </div>

                <div className={styles.tableWrapper}>
                  <table>
                    <thead>
                      <tr>
                        <th>{t("transaction id")}</th>
                        <th>{t("amount")}</th>
                      </tr>
                    </thead>

                    <tbody>
                      {(reservation?.transactions ?? []).map((item) => {
                        const { card_clearance, amount } = item;

                        return (
                          <tr key={nanoid()}>
                            <td>
                              <p>{card_clearance?.transaction_id ?? ""}</p>
                            </td>

                            <td>
                              <p>{currencyToFormat(Number(amount ?? "0"))}</p>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoadingContainer>
  );
}

export default Booking;
