import { paymentApi } from "./apiSingleton";
import { store } from "../store";

export const PaymentAPI = paymentApi.injectEndpoints({
  endpoints: (builder) => ({
    createOpaquePayment: builder.query({
      query: ({ pnr, source, transactions }) => {
        const { sessionKey: session, agency } = store.getState().session;
        const { payment_api_path: payment_path } = store.getState().environment;

        return {
          url: payment_path, // Set the URL to the payment path
          method: "POST",
          body: {
            agency,
            pnr,
            session,
            source,
            transactions,
          },
        };
      },
    }),
  }),
});

export const { useLazyCreateOpaquePaymentQuery } = PaymentAPI;
