// React hooks for managing component state, lifecycle, and references.
import { MutableRefObject, useEffect, useRef } from "react";
// Utility for generating unique IDs.
import { nanoid } from "@reduxjs/toolkit";
// Hook for internationalization, enabling multi-language support.
import { useTranslation } from "react-i18next";

// Hooks for dispatching actions and accessing Redux store state.
import { useTypedDispatch, useTypedSelector } from "../../../store/store";
// Action creators for showing modals, updating guest details, and setting pricing.
import { showModal } from "../../../store/slices/modalSlice";
import { GuestFields, updateGuest } from "../../../store/slices/guestsSlice";
import { setPricing } from "../../../store/slices/pricingSlice";
// Constants for modal types.
import MODAL from "../../../utils/constants/modal";

// Component to indicate loading state, and reusable UI components for buttons and room forms.
import LoadingContainer from "../../containers/LoadingContainer";
import Button from "../../shared/Button";
import RoomForms from "./molecules/RoomForms";
import SubmitButton from "./molecules/SubmitButton";
// Component for displaying booking details.
import BookingDetails from "../../base/BookingDetails";

// CSS module for styling.
import styles from "./index.module.scss";

type ValidateFormsOutput = Record<
  number,
  Record<
    number,
    {
      inputs: GuestFields;
      errors: GuestFields | null;
      expand: () => void;
      scrollIntoView: () => void;
    }
  >
>;

type SubmitButtonPayload = Record<
  number,
  Record<
    number,
    {
      inputs: GuestFields;
    }
  >
>;

function Passengers() {
  // Hooks for internationalization and dispatching Redux actions.
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();

  // State selectors for loading status and room details.
  const { isCruiseLoading } = useTypedSelector((state) => state.search);
  const { rooms } = useTypedSelector((state) => state.rooms);

  // Refs for managing room forms and the submit button.
  const submitButtonRef: MutableRefObject<{
    submit: (guests: SubmitButtonPayload) => void;
  }> = useRef({
    submit: () => null,
  });

  const roomsFormsRefs: Record<
    number,
    Record<
      number,
      MutableRefObject<{
        expand: () => void;
        scrollIntoView: () => void;
        validateForms: () => {
          inputs: GuestFields;
          errors: GuestFields | null;
        };
      } | null>
    >
  > = {};

  // Handler for displaying the itinerary modal.
  const handleShowItineraryModal = () => {
    dispatch(showModal({ type: MODAL.MODAL_TYPES.ITINERARY }));
  };

  // Function to validate all room forms and compile their outputs.
  const flattenForms = () => {
    let validationResult: ValidateFormsOutput = {};

    for (const roomNumber in roomsFormsRefs) {
      const room = roomsFormsRefs[+roomNumber];

      for (const formNumber in room) {
        const roomForms = room[+formNumber];
        const roomFormsValidationResult = roomForms.current?.validateForms();

        validationResult = {
          ...validationResult,
          ...(roomFormsValidationResult ?? {}),
        };
      }
    }

    return validationResult;
  };

  // Handler for the form validation process.
  const handleValidate = () => {
    const validationResult = flattenForms();

    let isFormsValid = true;

    for (const roomNumber in validationResult) {
      const room = validationResult[+roomNumber];

      for (const formKey in room) {
        const form = room[+formKey];
        const isFormValid = !form.errors;

        if (!isFormValid && isFormsValid) {
          requestAnimationFrame(() => {
            form.expand();

            setTimeout(() => {
              form.scrollIntoView();
            }, 500);
          });

          isFormsValid = false;
        }

        if (isFormValid) {
          dispatch(
            updateGuest({
              stateroomNumber: +roomNumber,
              guestNumber: +formKey,
              guestFields: form.inputs,
            }),
          );
        }
      }
    }

    if (isFormsValid) {
      submitButtonRef.current.submit(validationResult);
    }
  };

  useEffect(() => {
    dispatch(setPricing(rooms));
  }, [rooms]);

  // Effect hooks to set pricing information on mount and adjust scroll position.
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <LoadingContainer isLoading={isCruiseLoading || !rooms}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.left}>
            <h1 className={styles.title}>{t("who’s travelling")}</h1>

            <div className={styles.passengers}>
              {Object.entries(rooms ?? {}).map(
                ([stateroomKey, stateroom], stateroomIndex) => {
                  const ref = useRef(null);

                  if (!roomsFormsRefs[+stateroomKey]) {
                    roomsFormsRefs[+stateroomKey] = {};
                  }

                  roomsFormsRefs[+stateroomKey][stateroomIndex + 1] = ref;

                  return (
                    <div key={nanoid()} className={styles.passenger}>
                      <div className={styles.stateroomInfo}>
                        <p className={styles.subtitle}>
                          {t("stateroom")} {stateroomIndex + 1}
                        </p>

                        <p className={styles.name}>
                          {stateroom?.grade?.name} -{" "}
                          <span>
                            {stateroom?.grade?.descriptions?.[0].description}
                          </span>
                        </p>

                        <p className={styles.name}>
                          {stateroom?.guestsNumber} {t("guests")}
                        </p>
                        <p className={styles.name}>
                          {t("stateroom")} {stateroom?.cabin?.number}
                        </p>
                      </div>

                      <RoomForms
                        ref={ref}
                        isIncludesLead={stateroomIndex === 0}
                        roomNumber={+stateroomKey}
                        guestsAmount={stateroom?.guestsNumber ?? 1}
                      />
                    </div>
                  );
                },
              )}
            </div>

            <SubmitButton ref={submitButtonRef} onClick={handleValidate} />
          </div>

          <div className={styles.right}>
            <Button
              label={t("view itinerary")}
              variant="secondary"
              icon="FiPlus"
              onClick={handleShowItineraryModal}
            />

            <BookingDetails />
          </div>
        </div>
      </div>
    </LoadingContainer>
  );
}

export default Passengers;
