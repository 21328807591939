// Utility for conditionally joining class names based on certain conditions.
import classNames from "classnames";

// Importing specific SCSS module styles for styling the component.
import styles from "./index.module.scss";
// Importing a shared SvgButton component, presumably used for rendering a button with SVG icon.
import SvgButton from "../../../../shared/SvgButton";

// TypeScript interface defining the expected props for the Toast component.
interface IToastProps {
  isVisible: boolean; // Controls the visibility of the toast.
  message: string; // The message to be displayed inside the toast.
  type: string; // The type of toast, which may alter its appearance (e.g., success, error).
  closeToast: () => void; // Function to be called when the toast needs to be closed.
}

// Functional component definition accepting props adhering to IToastProps.
function Toast({ isVisible, message, type, closeToast }: IToastProps) {
  // The component returns a div element styled conditionally based on its props.
  return (
    // Using classNames to dynamically assign classes:
    // styles.container as the base class,
    // styles[`container_${type}`] to apply type-specific styling,
    // and [styles.container_visible] to toggle visibility based on the isVisible prop.
    <div
      className={classNames(styles.container, styles[`container_${type}`], {
        [styles.container_visible]: isVisible,
      })}>
      {/* The message prop is displayed within a span element. */}
      <span className={styles.message}>{message}</span>

      {/* SvgButton is used here as a close button. It calls closeToast function onClick.
      "icon" prop is used to specify which SVG icon the button will display, in this case, a cross. */}
      <SvgButton onClick={closeToast} icon="FiX" className={styles.close} />
    </div>
  );
}

// Exporting Toast component for use in other parts of the application.
export default Toast;
