import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Navigation from "./Navigation";
import { useTypedSelector } from "./store/store";

function App() {
  const { i18n } = useTranslation();

  const {
    app_language,
    primary_color,
    secondary_color,
    text_primary_color,
    text_secondary_color,
    input_border_color,
    input_background_color,
    input_placeholder_color,
    input_text_color,
    input_focus_border_color,
    error_color,
    menu_background_color,
    menu_text_color,
    element_background_primary,
    element_background_secondary,
    element_background_dark,
    background_color,
    link_color,
    section_background_color,
    shadow_color,
    subtext_color,
    svg_icons_primary,
    svg_icons_secondary,
    search_button_background,
    favicon,
  } = useTypedSelector((state) => state.environment);

  useEffect(() => {
    const styles = {
      "--primary-color": primary_color || "#007481",
      "--secondary-color": secondary_color || "#D5D4DD",
      "--text-primary-color": text_primary_color || "#3C3A59",
      "--text-secondary-color": text_secondary_color || "#FFFFFF",
      "--input-border-color": input_border_color || "#A7A7A7",
      "--input-background-color": input_background_color || "#FFFFFF",
      "--input-placeholder-color": input_placeholder_color || "#818181",
      "--input-text-color": input_text_color || "#545454",
      "--input-focus-border-color": input_focus_border_color || "#00407A",
      "--error-color": error_color || "#FA6B63",
      "--menu-background-color": menu_background_color || "#007481",
      "--menu-text-color": menu_text_color || "#FFFFFF",
      "--background-color": background_color || "#F5F5F5",
      "--link-color": link_color || "#007481",
      "--section-background-color": section_background_color || "#FFFFFF",
      "--shadow-color": shadow_color || "rgba(0, 0, 0, 0.05)",
      "--subtext-color": subtext_color || "#545454",
      "--element-background-primary": element_background_primary || "#FFFFFF",
      "--element-background-secondary":
        element_background_secondary || "#D5D4DD",
      "--element-background-dark": element_background_dark || "#3C3A59",
      "--svg-icons-primary": svg_icons_primary || "#3C3A59",
      "--svg-icons-secondary": svg_icons_secondary || "#3C3A59",
      "--search-button-background": search_button_background || "black",
    };

    Object.entries(styles).forEach(([property, value]) => {
      document.documentElement.style.setProperty(property, value);
    });
  }, [
    primary_color,
    secondary_color,
    text_primary_color,
    text_secondary_color,
    input_border_color,
    input_background_color,
    input_placeholder_color,
    input_text_color,
    input_focus_border_color,
    error_color,
    menu_background_color,
    menu_text_color,
    element_background_primary,
    element_background_secondary,
    element_background_dark,
    background_color,
    link_color,
    section_background_color,
    shadow_color,
    subtext_color,
    svg_icons_primary,
    search_button_background,
  ]);

  useEffect(() => {
    if (favicon) {
      const head = document.head;
      const link = document.createElement("link");

      link.setAttribute("rel", "icon");
      link.setAttribute("href", favicon);

      head.appendChild(link);
    }
  }, [favicon]);

  useEffect(() => {
    i18n.changeLanguage(app_language);
    localStorage.setItem("APP_LANG", app_language);
  }, [app_language]);

  return <Navigation />;
}

export default App;
