import {
  HashRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import useCurrentRoutes from "./hooks/useCurrentRoutes";
import { ROOT } from "./utils/constants/routes";

import ToastContainer from "./components/containers/ToastContainer";
import ModalContainer from "./components/containers/ModalContainer";
import RootGuard from "./components/guards/RootGuard";
import RootLayout from "./components/layouts/Root";

function Navigation() {
  const { routes } = useCurrentRoutes();

  return (
    <>
      <RootGuard>
        <Router>
          <ModalContainer>
            <Routes>
              {Object.entries(routes).map(([routeKey, route]) => {
                const { element: Element, layout } = route;

                return (
                  <Route
                    key={routeKey}
                    path={routeKey}
                    element={
                      <RootLayout type={layout}>
                        <Element />
                      </RootLayout>
                    }
                  />
                );
              })}

              <Route path="*" element={<Navigate to={ROOT} />} />
            </Routes>
          </ModalContainer>
        </Router>
      </RootGuard>

      <ToastContainer />
    </>
  );
}

export default Navigation;
