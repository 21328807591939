import { useTranslation } from "react-i18next";
import { nanoid } from "@reduxjs/toolkit";
import { Link } from "react-router-dom";
import classNames from "classnames";
import dayjs from "dayjs";

import { ReservationListInterface } from "../../../../../store/slices/reservationSlice";
import { useTypedDispatch, useTypedSelector } from "../../../../../store/store";
import { BOOKING, routeParams } from "../../../../../utils/constants/routes";
import { showModal } from "../../../../../store/slices/modalSlice";
import MODAL from "../../../../../utils/constants/modal";

import SvgButton from "../../../../shared/SvgButton";
import SvgIcon from "../../../../shared/SvgIcon";

import styles from "../../index.module.scss";

interface ContentProps {
  bookingList: ReservationListInterface[];
  sortBookingList: (type: string) => void;
}

function Content({ bookingList, sortBookingList }: ContentProps) {
  const dispatch = useTypedDispatch();
  const { t } = useTranslation();

  const { account_type } = useTypedSelector((state) => state.agency);

  const { date_format, pnr_column_name, cruise_column_name } = useTypedSelector(
    (state) => state.environment,
  );

  const showPaymentModal = (pnr: ReservationListInterface["pnr"]) => () => {
    dispatch(showModal({ type: MODAL.MODAL_TYPES.PAYMENT, data: pnr }));
  };

  return (
    <div className={styles.fieldsContainer}>
      <div className={styles.headerRow}>
        <div
          className={classNames(styles.column, styles.column_md)}
          style={{ cursor: "pointer" }}
          onClick={() => sortBookingList("booking_date")}>
          <span className={styles.uppercase}>{t("booked date")}</span>
          <div className={styles.svgBlock}>
            <SvgIcon className={styles.arrowUp} type="FiTriangle" />
            <SvgIcon className={styles.arrowDown} type="FiTriangle" />
          </div>
        </div>

        <div className={classNames(styles.column, styles.column_sm)}>
          <span className={styles.uppercase}>{pnr_column_name}</span>
        </div>
        <div
          className={classNames(styles.column, styles.column_md)}
          style={{ cursor: "pointer" }}
          onClick={() => sortBookingList("passenger_name")}>
          <span className={styles.uppercase}>{t("passenger name")}</span>
          <div className={styles.svgBlock}>
            <SvgIcon className={styles.arrowUp} type="FiTriangle" />
            <SvgIcon className={styles.arrowDown} type="FiTriangle" />
          </div>
        </div>
        <div
          className={classNames(styles.column, styles.column_md)}
          style={{ cursor: "pointer" }}
          onClick={() => sortBookingList("embarkation_date")}>
          <span className={styles.uppercase}>{t("embark date")}</span>
          <div className={styles.svgBlock}>
            <SvgIcon className={styles.arrowUp} type="FiTriangle" />
            <SvgIcon className={styles.arrowDown} type="FiTriangle" />
          </div>
        </div>
        <div className={classNames(styles.column, styles.column_lg)}>
          <span className={styles.uppercase}>{cruise_column_name}</span>
        </div>
        <div className={classNames(styles.column, styles.column_sm)}>
          <span className={styles.uppercase}>{t("status")}</span>
        </div>
        <div className={styles.actionColumn} />
      </div>
      {bookingList.map((booking) => {
        return (
          <div key={nanoid()} className={styles.contentRow}>
            <div className={classNames(styles.column, styles.column_md)}>
              <span>{dayjs(booking.created).format(date_format)}</span>
            </div>

            <div className={classNames(styles.column, styles.column_sm)}>
              <span>{booking.pnr}</span>
            </div>

            <div className={classNames(styles.column, styles.column_md)}>
              <span>{booking.lead_lastname}</span>
            </div>

            <div className={classNames(styles.column, styles.column_md)}>
              <span>{dayjs(booking.embarkation_date).format(date_format)}</span>
            </div>

            <div className={classNames(styles.column, styles.column_lg)}>
              <span>{booking.cruise_name}</span>
            </div>

            <div className={classNames(styles.column, styles.column_sm)}>
              <span>{booking.status}</span>
            </div>

            <div className={styles.actionColumn}>
              <SvgButton
                icon="FiDollarSign"
                iconClassName={styles.svgAction}
                onClick={showPaymentModal(booking.pnr)}
                isDisabled={
                  booking.status === "cancelled" ||
                  account_type === "creditaccount"
                }
              />

              <Link
                to={routeParams(BOOKING, [["pnr", booking.pnr]])}
                target="_blank">
                <SvgIcon className={styles.svgAction} type="FiExternalLink" />
              </Link>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Content;
