import { useMemo, useState } from "react"; // Import the useMemo hook from React to memoize values
import { nanoid } from "@reduxjs/toolkit"; // Import the nanoid function from Redux Toolkit to generate unique IDs
import classNames from "classnames"; // Import the classNames utility function for conditionally joining class names together

import SvgButton from "../SvgButton";

import styles from "./index.module.scss"; // Import the styles object containing CSS modules for the component

// Define the props interface for the Input component
interface IInputProps {
  className?: string; // Custom class name for styling
  value: string; // Value of the input field
  valueKey?: string; // Key associated with the value
  label?: string; // Label for the input field
  placeholder?: string; // Placeholder text for the input field
  autoComplete?: string; // Autocomplete attribute for the input field
  name?: string; // Name attribute for the input field
  type?: string; // Type of input field (e.g., text, password)
  errorMessage?: string; // Error message to display
  isRequired?: boolean; // Indicates if the input is required
  readOnly?: boolean; // Indicates if the input is read-only
  secured?: boolean;
  showError?: boolean; // Indicates whether to show error message
  onClick?: () => void; // Click event handler
  onChange?: ({ value, valueKey }: { value: string; valueKey: string }) => void; // Change event handler
  onBlur?: ({ value, valueKey }: { value: string; valueKey: string }) => void; // Blur event handler
}

/**
 * Input component for rendering an HTML input element.
 *
 * @param {string} className - Additional CSS class names for styling.
 * @param {string} value - The current value of the input.
 * @param {string} valueKey - The key associated with the input value.
 * @param {string} label - The label text for the input.
 * @param {string} placeholder - The placeholder text for the input.
 * @param {string} autoComplete - Autocompletion setting for the input.
 * @param {string} name - The name attribute for the input.
 * @param {string} type - The type of input (e.g., text, password).
 * @param {string} errorMessage - The error message to display.
 * @param {boolean} isRequired - Indicates if the input is required.
 * @param {boolean} readOnly - Indicates if the input is read-only.
 * @param {boolean} secured - Indicates if the input is read-only.
 * @param {boolean} showError - Indicates if the error message should be displayed.
 * @param {Function} onClick - The function to handle click events on the input.
 * @param {Function} onChange - The function to handle change events on the input.
 * @param {Function} onBlur - The function to handle blur events on the input.
 * @returns {JSX.Element} The Input component.
 */
function Input({
  className,
  value,
  valueKey,
  label,
  placeholder,
  autoComplete,
  name,
  type,
  errorMessage,
  isRequired,
  readOnly,
  secured,
  showError,
  onClick,
  onChange,
  onBlur,
}: IInputProps) {
  const [inputType, setInputType] = useState(secured ? "password" : type);

  // Generate a unique ID for the input element
  const id = useMemo(nanoid, []);

  // Click event handler
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  // Change event handler
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange({ value: e.target.value, valueKey: valueKey ?? "" });
    }
  };

  // Blur event handler
  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur({ value: e.target.value, valueKey: valueKey ?? "" });
    }
  };

  const handleIconClick = () => {
    const nextInputType = inputType === "password" ? type : "password";

    setInputType(nextInputType);
  };

  return (
    <div className={classNames(styles.container, className)}>
      {label && (
        <label htmlFor={id} className={styles.label}>
          {label}
          {isRequired && <span className={styles.asterisk}>*</span>}
        </label>
      )}

      <div className={styles.fieldContainer}>
        <input
          id={id}
          type={inputType}
          className={classNames(styles.field, {
            [styles.field_error]: !!errorMessage,
            [styles.field_with_icon]: secured,
          })}
          value={value}
          placeholder={placeholder}
          name={name}
          readOnly={readOnly}
          autoComplete={autoComplete}
          onClick={handleClick}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        {secured && (
          <SvgButton
            className={styles.icon}
            icon={inputType === "password" ? "FiEyeOff" : "FiEye"}
            onClick={handleIconClick}
          />
        )}
      </div>

      {showError && <span className={styles.error}>{errorMessage}</span>}
    </div>
  );
}

// Default props for the Input component
Input.defaultProps = {
  className: "",
  valueKey: "",
  label: "",
  placeholder: "",
  autoComplete: "",
  name: "",
  type: "text",
  errorMessage: "",
  isRequired: false,
  readOnly: false,
  secured: false,
  showError: true,
  handleClick: () => {},
  onChange: () => {},
};

// Export the Input component as default
export default Input;
