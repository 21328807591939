import { createSlice } from "@reduxjs/toolkit"; // Import createSlice function from the Redux Toolkit

// Import necessary types and services from the AgencyService module
import {
  AgencyAPI,
  IInitConsortiaResponse,
  IInitMarketsResponse,
  IInitRegulatoryResponse,
} from "../services/AgencyService";

// Define the shape of the AgencyState
export interface AgencyState {
  account_type: "creditaccount" | "creditcard" | "both" | "";
  consortia: IInitConsortiaResponse; // Array of consortia
  markets: IInitMarketsResponse; // Array of markets
  regulatory: IInitRegulatoryResponse; // Array of regulatory
  commission_level: string;
  created_by: string;
  market: string;
}

// Define the initial state for the agency slice
const initialState: AgencyState = {
  account_type: "",
  consortia: [], // Initialize consortia as an empty array
  markets: [], // Initialize markets as an empty array
  regulatory: [], // Initialize regulatory as an empty array
  commission_level: "0.00",
  created_by: "",
  market: "",
};

// Create the agencySlice using createSlice function
const agencySlice = createSlice({
  name: "agency", // Name of the slice
  initialState, // Initial state
  reducers: {}, // Reducer functions (currently empty)
  extraReducers: (builder) => {
    // Add extra reducers for handling actions dispatched by the AgencyAPI
    builder
      .addMatcher(
        // Handle fulfilled action for initializing consortia
        AgencyAPI.endpoints.initConsortia.matchFulfilled,
        (state, action) => {
          // Update the state with the payload received from the action
          state.consortia = action.payload;
        },
      )
      .addMatcher(
        // Handle fulfilled action for initializing markets
        AgencyAPI.endpoints.initMarkets.matchFulfilled,
        (state, action) => {
          // Update the state with the payload received from the action
          state.markets = action.payload;
        },
      )
      .addMatcher(
        // Handle fulfilled action for initializing markets
        AgencyAPI.endpoints.initRegulatory.matchFulfilled,
        (state, action) => {
          // Update the state with the payload received from the action
          state.regulatory = action.payload;
        },
      )
      .addMatcher(
        // Handle fulfilled action for initializing agency details
        AgencyAPI.endpoints.getAgencyDetails.matchFulfilled,
        (state, action) => {
          // Update the state with the payload received from the action
          state.commission_level = action.payload.commission_level;
          state.created_by = action.payload.created_by;
          state.market = action.payload.market;
          state.account_type = action.payload.account_type;
        },
      );
  },
});

// Export the reducer function generated by the agencySlice
export default agencySlice.reducer;
