import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ModalState {
  type: string;
  data?: any;
  size?: "sm" | "md" | "lg";
  scrollY?: number;
}

const initialState: ModalState = {
  type: "",
  data: null,
  size: "sm",
  scrollY: 0,
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    showModal(state: ModalState, action: PayloadAction<ModalState>) {
      state.type = action.payload.type;
      state.size = action.payload.size;
      state.data = action.payload.data;
    },
    hideModal(state: ModalState) {
      state.type = "";
      state.data = null;
      state.size = "sm";
    },
    setScrollY(state: ModalState, action: PayloadAction<number>) {
      state.scrollY = action.payload;
    },
  },
});

export const { showModal, hideModal, setScrollY } = modalSlice.actions;

export default modalSlice.reducer;
