// Import necessary dependencies
import { CSSProperties } from "react";
import classNames from "classnames"; // Importing classNames library for managing CSS class names
import Icons from "react-icons/fi";

import SvgIcon from "../SvgIcon"; // Importing SvgIcon component

import styles from "./index.module.scss"; // Importing CSS module styles

// Define props interface for SvgButton component
interface ISvgButtonProps {
  icon: keyof typeof Icons;
  className?: string;
  iconClassName?: string;
  onClick: () => void;
  style?: CSSProperties;
  isDisabled?: boolean;
}

function SvgButton({
  icon,
  className,
  iconClassName,
  onClick,
  style,
  isDisabled,
}: ISvgButtonProps) {
  return (
    <button
      style={style}
      type="button"
      tabIndex={-1}
      aria-label={icon}
      className={classNames(styles.button, className, {
        [styles.button_disabled]: isDisabled,
      })}
      onClick={onClick}
      disabled={isDisabled}>
      <SvgIcon type={icon} className={classNames(styles.icon, iconClassName)} />
    </button>
  );
}

// Default props for SvgButton component
SvgButton.defaultProps = {
  className: "", // Default empty className
};

// Export SvgButton component
export default SvgButton;
